* {
    box-shadow: none !important;
}

body {
    font-size: 60%;
    padding: 0;
    margin: 0;
    background: #fff !important;
}

#header,
#footer,
[class^=as-panel-header--],
.as-form-group--controls,
.as-menu,
.as-button,
.as-hide-on-print {
    display: none !important;
}

.as-form-group,
.as-field,
.as-panel,
.as-panel-header,
.as-panel-body,
.as-panel-footer {
    background: #fff !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    color: #000 !important;
}

.as-form-group {
    margin-bottom: 2em !important;
    padding-bottom: 1em !important;
    border-bottom: 1px solid #ddd !important;
}

.as-field {
    display: inline-block;
    margin-right: 1em !important;
}

.as-row {
    max-width: none;
    min-width: 0;
    width: 100% !important;
    margin: 1em 0 !important;
}

.as-blocklist {
    margin: 0;
    padding: 0;
}

.as-blocklist > li {
    float: none !important;
    clear: none !important;
    display: inline-block;
    padding: 0;
    margin: 0 1em 0 0;
    border: none !important;
}

.as-col {
    padding: 0 !important;
    width: auto !important;
    float: none !important;
}

h1, .as-alpha {
    font-size: 14pt !important;
    font-weight: bold;
    color: #222;
    padding: 0 !important;
    margin: 0.5em 0 !important;
}

h2, .as-beta {
    font-size: 12pt !important;
    font-weight: bold;
    padding: 0 !important;
    margin: 0.5em 0 !important;
}

h3, .as-gamma,
h4, .as-delta,
h5, .as-epsilon,
h6, .as-zeta {
    font-size: 10pt !important;
    font-weight: bold;
    padding: 0 !important;
    margin: 1em 0 !important;
}

.as-pageheader {
    font-size: 16pt !important;
    margin: 0 0 1em 0 !important;
    border-bottom: 2px solid #000;
}

.as-split {
    text-align: left;
}

.as-split-title {
    display: inline-block;
    min-width: 12em;
    margin-right: 2em;
}

hr {
    margin: 1em 0 !important;
    border: none !important;
    border-bottom: 1px dashed #ddd !important;
}

/* Print styles for lists */

colgroup col {
    width: auto !important;
}

.ListBodyContainer {
    left: 0 !important;
    width: 100% !important;
    top: 0 !important;
}

.ListBodyContainer * {
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
}
.ListBodyContainer *:after {
    background: none;
}

.ListBodyContainer input[type=checkbox] {
    display: none;
}

.FilterFormContainer, #report_header {
    display: none !important;
}

table.column_row {
    table-layout: auto !important;
}
.ListBodyContainer th {
    visibility: visible !important;
}


.column_header_frame, .column_footer_frame {
    display: none !important;
}

.report_list_frame td a {
    font-weight: normal;
}

.report_list_frame, .column_row_frame {
    overflow: visible;
}

.table_record_count, .SelectedRowCount {
    display: none;
}

.column_header, .column_footer {
    display: none;
    visibility: hidden;
}

#operation_panel {
    display: none;
}

.report_list_frame th a {
    color: black;
}

.sort_arrow {
    display: none;
}

.report_list_frame .column_row_frame th {
    visibility: visible;
}

#row_menu {
    display: none;
}

.column_row_frame td {
    vertical-align: top;
}

td[style="width: 1.999em; text-align: center;"], th[style="width: 1.999em; text-align: center;"] {
    display: none;
}

